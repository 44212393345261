import { Application } from "@hotwired/stimulus"

// Application
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Exports
export { application }
