// Imports
import { application } from "./application";

// Controllers
import ApplicationController from "./application_controller";
import HomeController from "./home_controller";
import QuizzesController from "./quizzes_controller";
import SessionController from "./session_controller";

// Registers
application.register("application", ApplicationController);
application.register("home", HomeController);
application.register("quizzes", QuizzesController);
application.register("session", SessionController);
